<template>
  <div>
    <!-- This example requires Tailwind CSS v2.0+ -->
    <div class="relative">
      <div class="mx-auto max-w-7xl">
        <div
          class="relative z-0 pb-8 bg-white sm:pb-16 md:pb-20 lg:max-w-2xl lg:w-full lg:pb-28 xl:pb-32"
        >
          <main
            class="z-0 px-4 mx-auto mt-10 max-w-7xl sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-28"
          >
            <div class="sm:text-center lg:text-left">
              <h1
                class="text-5xl font-extrabold tracking-tight text-center text-gray-900 md:text-6xl sm:text-left"
              >
                <span class="block xl:inline">Vi är </span>
                <span class="block text-pink-300 xl:inline">WinterZone AB</span>
              </h1>
              <p
                class="mt-12 text-base text-center text-gray-600 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0 sm:text-left"
              >
                WinterZone AB grundades mitt i en coronapandemi som ett
                familjeföretag med verksamhet inom
                <a class="text-green-300 underline" href="#/3d">3D-printning</a
                >,
                <a class="text-pink-300 underline" href="#/hosting">hosting</a>
                men också
                <a class="text-blue-300 underline" href="#/interactive"
                  >webb- och apputveckling</a
                >.
              </p>
            </div>
          </main>
        </div>
      </div>
    </div>
  </div>
</template>
