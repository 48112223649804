<template>
  <div>
    <!-- This example requires Tailwind CSS v2.0+ -->
    <div class="relative">
      <div class="mx-auto max-w-7xl">
        <div data-aos="fade-right" data-aos-duration="1200" class="relative">
          <main
            class="px-4 mx-auto mt-10 max-w-7xl sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-28"
          >
            <div class="text-center xl:text-left">
              <h1
                class="text-4xl font-extrabold tracking-tight text-gray-900 sm:text-5xl md:text-6xl"
              >
                <span class="xl:inline">WinterZone </span>
                <span class="text-pink-300 xl:inline">Hosting</span>
              </h1>
              <p
                class="mt-3 mb-12 text-base text-gray-900 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl xl:mx-0"
              >
                Här på WinterZone erbjuder vi servrar som befinner sig i Sverige
                och följer en enkel prismodell. Dessutom med en kundtjänst som
                värnar om din satisfaktion och talar svenska.
              </p>
              <div
                class="mt-5 sm:mt-8 sm:flex sm:justify-center xl:justify-start"
              >
                <div class="rounded-md shadow">
                  <a
                    href="https://frozen.winterzone.se/"
                    class="flex justify-center items-center px-8 py-3 w-full text-base font-medium text-white bg-pink-300 rounded-md border border-transparent hover:bg-pink-400 md:py-4 md:text-lg md:px-10"
                  >
                    Jag vill beställa en Minecraft server.*
                  </a>
                </div>
              </div>
              <p class="mt-2 font-mono text-xs text-gray-700 lowercase">
                * Vi erbjuder givetvis andra - skräddarsydda - lösningar.
              </p>
            </div>
          </main>
        </div>
      </div>
      <div
        data-aos="zoom-in-right"
        data-aos-duration="1200"
        class="hidden lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2 xl:block"
      >
        <img src="../assets/img/hosting/servers.png" alt="" />
      </div>
    </div>

    <!-- This example requires Tailwind CSS v2.0+ -->
    <div class="py-12 mt-96 bg-white">
      <div class="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
        <div class="text-center">
          <h2
            class="mt-2 text-3xl font-extrabold tracking-tight leading-8 text-gray-900 sm:text-4xl"
          >
            Anmärkningsvärt
          </h2>
        </div>

        <div class="mt-10">
          <dl
            class="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-10"
          >
            <div data-aos="fade-right" data-aos-duration="1200" class="flex">
              <div class="flex-shrink-0">
                <div
                  class="flex justify-center items-center w-12 h-12 text-white bg-pink-300 rounded-md"
                >
                  <!-- Heroicon name: database -->
                  <svg
                    class="w-6 h-6"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M4 7v10c0 2.21 3.582 4 8 4s8-1.79 8-4V7M4 7c0 2.21 3.582 4 8 4s8-1.79 8-4M4 7c0-2.21 3.582-4 8-4s8 1.79 8 4m0 5c0 2.21-3.582 4-8 4s-8-1.79-8-4"
                    ></path>
                  </svg>
                </div>
              </div>
              <div class="ml-4">
                <dt class="text-lg font-medium leading-6 text-gray-900">
                  NVMe M.2 SSD
                </dt>
                <dd class="mt-2 text-base text-gray-500">
                  Med fokus på hastighet så utrustas våra serverdatorer med
                  hårddiskar på &asymp;3 GB läs-/skrivhastighet, allt för att
                  maximera din uplevelse.
                </dd>
              </div>
            </div>

            <div data-aos="fade-left" data-aos-duration="1200" class="flex">
              <div class="flex-shrink-0">
                <div
                  class="flex justify-center items-center w-12 h-12 text-white bg-pink-300 rounded-md"
                >
                  <!-- Heroicon name: chip -->
                  <svg
                    class="w-6 h-6"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M9 3v2m6-2v2M9 19v2m6-2v2M5 9H3m2 6H3m18-6h-2m2 6h-2M7 19h10a2 2 0 002-2V7a2 2 0 00-2-2H7a2 2 0 00-2 2v10a2 2 0 002 2zM9 9h6v6H9V9z"
                    ></path>
                  </svg>
                </div>
              </div>
              <div class="ml-4">
                <dt class="text-lg font-medium leading-6 text-gray-900">
                  Ryzen processorer
                </dt>
                <dd class="mt-2 text-base text-gray-500">
                  Majoriteten av våra serverdatorer använder tredje
                  generationens Ryzen 7 (eller nyare) för att maximera
                  prestandan och bihålla humana priser.
                </dd>
              </div>
            </div>

            <div data-aos="fade-right" data-aos-duration="1200" class="flex">
              <div class="flex-shrink-0">
                <div
                  class="flex justify-center items-center w-12 h-12 text-white bg-pink-300 rounded-md"
                >
                  <!-- Heroicon name: microphone -->
                  <svg
                    class="w-6 h-6"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M19 11a7 7 0 01-7 7m0 0a7 7 0 01-7-7m7 7v4m0 0H8m4 0h4m-4-8a3 3 0 01-3-3V5a3 3 0 116 0v6a3 3 0 01-3 3z"
                    ></path>
                  </svg>
                </div>
              </div>
              <div class="ml-4">
                <dt class="text-lg font-medium leading-6 text-gray-900">
                  Kundtjänst på Discord
                </dt>
                <dd class="mt-2 text-base text-gray-500">
                  Vår kundtjänst befinner sig på de plattformar du använder och
                  hör gladeligen av oss via röstsamtal. Titta in WinterZone's
                  <a
                    href="https://discord.gg/aAYNK7UmAX"
                    class="font-bold text-pink-300"
                    >Discord-server</a
                  >.
                </dd>
              </div>
            </div>

            <div data-aos="fade-left" data-aos-duration="1200" class="flex">
              <div class="flex-shrink-0">
                <div
                  class="flex justify-center items-center w-12 h-12 text-white bg-pink-300 rounded-md"
                >
                  <!-- Heroicon name: document-text -->
                  <svg
                    class="w-6 h-6"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
                    ></path>
                  </svg>
                </div>
              </div>
              <div class="ml-4">
                <dt class="text-lg font-medium leading-6 text-gray-900">
                  99.95% Servicenivåavtal
                </dt>
                <dd class="mt-2 text-base text-gray-500">
                  Driftstopp är aldrig kul för någon, på grund av detta
                  garanterar vår SLA ett maximalt driftstopp på 3 timmar och 39
                  minuter per månad.
                </dd>
              </div>
            </div>
          </dl>
        </div>
      </div>
    </div>
    <div class="py-12 mt-48 bg-white">
      <div class="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
        <div class="text-center">
          <h2
            data-aos="zoom-in-down"
            data-aos-duration="1000"
            class="mt-2 text-3xl font-extrabold tracking-tight leading-8 text-gray-900 sm:text-4xl"
          >
            Varför välja oss?
          </h2>
          <p
            data-aos="zoom-in-up"
            data-aos-duration="1000"
            class="mx-auto mt-4 max-w-2xl text-xl text-gray-500"
          >
            Kännetecken för ett medelmåttligt serverhotell är en hög ping, udda
            prissättning och otrevlig kundtjänst. När vi tillslut fick nog av
            allt trassel skapade vi något eget och våra omdömen talar för sig
            självt.
          </p>

          <div class="flex flex-col text-center lg:flex-row lg:justify-around">
            <div
              data-aos="zoom-in-right"
              data-aos-duration="1000"
              class="mx-auto mt-24 text-xl text-gray-500 lg:max-w-2xl lg:w-1/3"
            >
              <p class="text-2xl">
                "Min erfarenhet med WinterZone Hosting har varit grymt bra och
                jag kan inte komma på något dåligt alls. Kundtjänsten är alert
                och jag får alltid snabba svar."<span
                  class="font-mono text-gray-300 uppercase"
                  >*</span
                >
              </p>
              <p class="mt-4">
                <span class="font-bold">Gaffla</span>
                <span class="mx-3 text-3xl font-bold text-pink-300">/</span>
                Twitch-strömmare, trustpilot.com
              </p>
            </div>
            <div
              data-aos="zoom-in-left"
              data-aos-duration="1000"
              class="mx-auto mt-24 text-xl text-gray-500 lg:max-w-2xl lg:w-1/3"
            >
              <p class="text-2xl">
                "Lite strul i början men sen fixade det sig... ...Det är också
                bra priser och prestanda... ...om det är bra support du letar
                efter då har du verkligen kommit rätt!..."
              </p>
              <p class="mt-4">
                <span class="font-bold">Alexander</span>
                <span class="mx-3 text-3xl font-bold text-pink-300">/</span>
                trustpilot.com
              </p>
            </div>
          </div>

          <div class="flex flex-col mt-8 lg:flex-row lg:justify-around">
            <div
              data-aos="zoom-in-right"
              data-aos-duration="1000"
              class="mx-auto mt-24 text-xl text-gray-500 lg:max-w-2xl lg:w-1/3"
            >
              <p class="text-2xl">
                "Detta är en av de bästa hosting- erfarenheterna jag någonsin
                haft! Utmärkt kundtjänst och prestanda."<span
                  class="font-mono text-gray-300 uppercase"
                  >*</span
                >
              </p>
              <p class="mt-4">
                <span class="font-bold">EDDE_PEDDE</span>
                <span class="mx-3 text-3xl font-bold text-pink-300">/</span>
                trustpilot.com
              </p>
            </div>
            <div
              data-aos="zoom-in-left"
              data-aos-duration="1000"
              class="mx-auto mt-24 text-xl text-gray-500 lg:max-w-2xl lg:w-1/3"
            >
              <p class="text-2xl">
                "Väldigt snabb/bra kundtjänst och övergripande upplevelse."<span
                  class="font-mono text-gray-300 uppercase"
                  >*</span
                >
              </p>
              <p class="mt-12">
                <span class="font-bold">S K O G I S</span>
                <span class="mx-3 text-3xl font-bold text-pink-300">/</span>
                trustpilot.com
              </p>
            </div>
          </div>

          <p class="mt-8 font-mono text-gray-300 uppercase">
            * Följande omdöme är översatt från Engelska till Svenska av någon på
            WinterZone AB. <br />Du kan hitta originalet av detta omdöme på
            <a
              class="font-bold text-pink-300 hover:text-pink-400"
              href="https://trustpilot.com/review/winterzone.se?languages=all"
              >trustpilot.com</a
            >
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
