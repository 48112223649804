import { createRouter, createWebHashHistory } from "vue-router";
import Home from "../views/Home.vue";
// import Attack from "../views/Attack.vue";
import Contact from "../views/Contact.vue";
import threed from "../views/threed.vue";
import threedterms from "../views/threedterms.vue";
import threedprivacy from "../views/threedprivacy.vue";
import threedforsaljning from "../views/forsaljningthreed.vue";
import forsaljning from "../views/forsaljning.vue";
import Hosting from "../views/Hosting.vue";
import hostingterms from "../views/hostingterms.vue";
import Interactive from "../views/Interactive.vue";
import sitemap from "../views/sitemap.vue";

import hostingsla from "../views/sla.vue";
import hostingprivacy from "../views/privacy.vue";
import hostingtos from "../views/tos.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  /*{
    path: "/data",
    name: "Attack",
    component: Attack,
  },*/
  {
    path: "/contact",
    name: "Contact",
    component: Contact,
  },
  {
    path: "/3d",
    name: "3d",
    component: threed,
  },
  {
    path: "/3d-terms",
    name: "3d terms",
    component: threedterms,
  },
  {
    path: "/3d-privacy",
    name: "3d privacy",
    component: threedprivacy,
  },
  {
    path: "/3d-forsaljning",
    name: "3d forsaljning",
    component: threedforsaljning,
  },
  {
    path: "/forsaljning",
    name: "forsaljning",
    component: forsaljning,
  },
  {
    path: "/hosting",
    name: "Hosting",
    component: Hosting,
  },
  {
    path: "/hosting-terms",
    name: "Hosting terms",
    component: hostingterms,
  },
  {
    path: "/interactive",
    name: "Interactive",
    component: Interactive,
  },
  {
    path: "/hosting-sla",
    name: "SLA",
    component: hostingsla,
  },
  {
    path: "/hosting-privacy",
    name: "Privacy",
    component: hostingprivacy,
  },
  {
    path: "/hosting-tos",
    name: "Tos",
    component: hostingtos,
  },
  {
    path: "/sitemap",
    name: "Sitemap",
    component: sitemap,
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
