<template>
  <div>
    <!-- This example requires Tailwind CSS v2.0+ -->
    <div class="relative">
      <div class="mx-auto max-w-7xl">
        <div data-aos="fade-right" data-aos-duration="1200" class="relative">
          <main
            class="px-4 mx-auto mt-10 max-w-7xl sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-28"
          >
            <div class="text-center lg:text-left">
              <h1
                class="text-4xl font-extrabold tracking-tight text-gray-900 sm:text-6xl md:text-6xl"
              >
                <span class="xl:inline">WinterZone </span>
                <span class="text-blue-300 xl:inline">Interactive</span>
              </h1>
              <p
                class="mt-3 mb-12 text-base text-gray-900 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0"
              >
                Lösningar för alla. Oavsett om det är en enkel webbplats för att
                redovisa vad ditt bolag sysslar med, bloggsystem eller något
                helt annat så finns vi här vid din sida och bygger något från
                grunden.
              </p>
              <div
                class="mt-8 md:mt-5 sm:flex sm:justify-center lg:justify-start"
              >
                <div class="rounded-md shadow">
                  <router-link to="contact">
                    <a
                      href="#"
                      class="flex justify-center items-center px-8 py-3 w-full text-base font-medium text-white bg-blue-300 rounded-md border border-transparent hover:bg-blue-400 md:py-4 md:text-lg md:px-10"
                    >
                      Kontakta oss
                    </a>
                  </router-link>
                </div>
                <div class="mt-3 sm:mt-0 sm:ml-3">
                  <a
                    class="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-blue-400 bg-blue-100 md:py-4 md:text-lg md:px-10 cursor-default <!--hover:bg-blue-300 hover:text-white-->"
                  >
                    Projektportalen*
                  </a>
                </div>
              </div>
              <p class="mt-2 font-mono text-gray-300 uppercase">
                * Kommer snart.
              </p>
            </div>
          </main>
        </div>
      </div>
      <div
        data-aos="zoom-in-right"
        data-aos-duration="1200"
        class="hidden lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2 xl:block"
      >
        <img class="w-9/12" src="../assets/img/interactive/dev.png" alt="" />
      </div>
    </div>

    <div class="p-12 mt-96 bg-gray-800 sm:p-36">
      <div class="text-center">
        <h2
          data-aos="fade-in"
          data-aos-duration="1200"
          class="mt-2 mb-12 text-3xl font-extrabold tracking-tight leading-8 text-center text-white sm:text-4xl"
        >
          Prisuppskattning
        </h2>
      </div>

      <div class="flex flex-col lg:flex-row">
        <div
          data-aos="fade-right"
          data-aos-duration="1200"
          class="p-8 mt-6 text-center bg-white rounded-lg lg:w-1/2 lg:mx-6"
        >
          <span
            class="px-4 py-0.5 font-medium tracking-wide text-blue-500 uppercase bg-blue-100 rounded-full"
            >Standard</span
          >
          <p class="mt-3 text-3xl font-extrabold text-gray-800 md:text-6xl">
            5-12kkr
          </p>
          <p class="mt-6 text-xl text-gray-500">
            En landningssida för ditt företag och dess podukter eller dylikt.
          </p>
        </div>
        <div
          data-aos="fade-left"
          data-aos-duration="1200"
          class="p-8 mt-6 text-center bg-white rounded-lg lg:w-1/2 g:mx-6"
        >
          <span
            class="px-4 py-0.5 font-medium tracking-wide text-yellow-500 uppercase bg-yellow-100 rounded-full"
            >Premium</span
          >
          <p class="mt-3 text-3xl font-extrabold text-gray-800 md:text-6xl">
            15kkr+
          </p>
          <p class="mt-6 text-xl text-gray-500">
            Alla möjliga sorters webb- och applösningar där en databas är
            inblandad.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
