<template>
  <div>
    <!-- This example requires Tailwind CSS v2.0+ -->
    <div class="relative">
      <div class="mx-auto max-w-7xl">
        <div data-aos="fade-right" data-aos-duration="1200" class="relative">
          <main
            class="px-4 mx-auto mt-10 max-w-7xl text-center sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-28"
          >
            <div class="text-center xl:text-left">
              <h1
                class="text-6xl font-extrabold tracking-tight text-gray-900 sm:text-5xl md:text-6xl"
              >
                <span class="xl:inline">WinterZone </span>
                <span class="text-green-400 xl:inline">3D</span>
              </h1>
              <p
                class="mt-3 mb-12 text-base text-gray-7np00 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl xl:mx-0"
              >
                Visste du att det går att printa allt möjligt i plast med en
                3D-printer? Tack vare 3D-printning går det nu att göra allt från
                reservdelar till dekorationer.
              </p>
              <div
                class="mt-5 sm:mt-8 sm:flex sm:justify-center xl:justify-start"
              >
                <div class="rounded-md shadow">
                  <a
                    href="https://shop.winterzone.se/"
                    class="flex justify-center items-center px-8 py-3 w-full text-base font-medium text-white bg-green-300 rounded-md border border-transparent hover:bg-green-400 md:py-4 md:text-lg md:px-10"
                  >
                    Webbshop
                  </a>
                </div>
                <div class="mt-3 sm:mt-0 sm:ml-7">
                  <a
                    class="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-green-400 bg-green-100 md:py-4 md:text-lg md:px-10 cursor-default <!--hover:bg-pink-300 hover:text-white-->"
                  >
                    Offertförfrågan*
                  </a>
                </div>
                <a
                  href="../pdfs2/katalog.pdf"
                  download="katalog_augusti_2023_winterzone.pdf"
                  class="flex justify-center items-center px-8 py-3 mt-4 ml-0 w-full text-base font-medium text-white bg-green-500 rounded-md border border-transparent xl:hidden sm:ml-4 sm:mt-0 hover:bg-green-600 md:py-4 md:text-lg md:px-10"
                >
                  Katalog (Augusti 2023)
                </a>
              </div>
              <div
                class="justify-self-center self-center mt-4 w-1/3 rounded-md shadow xl:justify-start"
              >
                <a
                  href="/pdfs2/katalog.pdf"
                  download="katalog_augusti_2023_winterzone.pdf"
                  class="flex hidden justify-center items-center px-8 py-3 w-full text-base font-medium text-center text-white bg-green-500 rounded-md border border-transparent xl:block hover:bg-green-600 md:py-2 md:text-lg md:px-10"
                >
                  Katalog (Augusti 2023)
                </a>
              </div>
              <p class="mt-2 font-mono text-gray-500 uppercase">
                * Kommer snart, tills vidare kan du skicka ett <br />mail till
                <a
                  class="text-green-300 underline"
                  href="mailto:support@winterzone.se"
                  >support@winterzone.se</a
                >.
              </p>
            </div>
          </main>
        </div>
      </div>
      <div
        data-aos="zoom-in-right"
        data-aos-duration="1200"
        class="hidden xl:absolute xl:inset-y-0 xl:right-0 xl:w-1/2 xl:block"
      >
        <img width="420" src="../assets/img/3d/3d-printer.svg" alt="" />
      </div>
    </div>

    <!--Will be added and overhauld once the WinterZone News API is live... <div class="flex justify-center mt-72 text-center">
            <div>
                <a href="#/3d">
                    <svg class="mt-16 mr-24 w-24 h-24 text-green-300" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 19l-7-7m0 0l7-7m-7 7h18"></path></svg>
                </a>
            </div>
            <div>
                <img class="rounded-xl" src="https://via.placeholder.com/250" alt="">
                <h1 class="pt-4 text-xl font-bold">Nyhetstitel 1</h1>
                <p class="text-gray-400">2021-04-01</p>
            </div>

            <div class="mx-72">
                <img class="rounded-xl" src="https://via.placeholder.com/250" alt="">
                <h1 class="pt-4 text-xl font-bold">Nyhetstitel 2</h1>
                <p class="text-gray-400">2021-04-02</p>
            </div>

            <div>
                <img class="rounded-xl" src="https://via.placeholder.com/250" alt="">
                <h1 class="pt-4 text-xl font-bold">Nyhetstitel 3</h1>
                <p class="text-gray-400">2021-04-03</p>
            </div>
            <div>
                <a href="#/3d">
                    <svg class="mt-16 ml-24 w-24 h-24 text-green-300" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 5l7 7m0 0l-7 7m7-7H3"></path></svg>
                </a>
            </div>
        </div>-->

    <div class="mt-48 text-center">
      <h2
        class="mt-2 text-3xl font-extrabold tracking-tight leading-8 text-gray-900 sm:text-4xl"
      >
        Att skapa 3D-filer
      </h2>
      <p class="p-12 mx-auto text-lg text-gray-900 xl:max-w-3xl">
        3D-utskrifter baseras på 3D-filer, det vanligaste filformatet är STL,
        men även OBJ och 3DMF kan användas. Övriga 3D-format måste konverteras
        innan utskrift, om WinterZone skall utföra konvertering tillkommer
        kostnad för detta. 3D-filerna skapas med hjälp av 3D-CAD, saknar du
        3D-fil och möjlighet till 3D-CAD hjälper vi dig gärna med att CAD:a din
        detalj, vi behöver då underlag i form av skisser, foton eller liknade.
        Kontakta oss per mail
        <a
          class="text-green-300 underline hover:text-green-400"
          href="mailto:suport@winterzone.se"
          >support@winterzone.se</a
        >
        för att diskutera just din idé så återkommer vi med en offert.
      </p>
    </div>

    <!-- This example requires Tailwind CSS v2.0+ -->
    <div class="py-12 mt-48 bg-white sm:mt-96">
      <div class="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
        <div class="text-center">
          <h2
            class="mt-2 text-3xl font-extrabold tracking-tight leading-8 text-gray-900 sm:text-4xl"
          >
            Anmärkningsvärt
          </h2>
        </div>

        <div class="mt-10">
          <dl
            class="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-10"
          >
            <div data-aos="fade-right" data-aos-duration="1200" class="flex">
              <div class="flex-shrink-0">
                <div
                  class="flex justify-center items-center w-12 h-12 text-white bg-green-300 rounded-md"
                >
                  <!-- Heroicon name: arrows-expand -->
                  <svg
                    class="w-6 h-6"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M4 8V4m0 0h4M4 4l5 5m11-1V4m0 0h-4m4 0l-5 5M4 16v4m0 0h4m-4 0l5-5m11 5l-5-5m5 5v-4m0 4h-4"
                    ></path>
                  </svg>
                </div>
              </div>
              <div class="ml-4">
                <dt class="text-lg font-medium leading-6 text-gray-900">
                  Storlek
                </dt>
                <dd class="mt-2 text-base text-gray-500">
                  Vi har tillfället utskriftskapaciteten på objekt upp till
                  300x300x400 mm, större objekt kan delas upp i flera utskrifter
                  och limmas ihop.
                </dd>
              </div>
            </div>

            <div data-aos="fade-left" data-aos-duration="1200" class="flex">
              <div class="flex-shrink-0">
                <div
                  class="flex justify-center items-center w-12 h-12 text-white bg-green-300 rounded-md"
                >
                  <!-- Heroicon name: beaker -->
                  <svg
                    class="w-6 h-6"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M19.428 15.428a2 2 0 00-1.022-.547l-2.387-.477a6 6 0 00-3.86.517l-.318.158a6 6 0 01-3.86.517L6.05 15.21a2 2 0 00-1.806.547M8 4h8l-1 1v5.172a2 2 0 00.586 1.414l5 5c1.26 1.26.367 3.414-1.415 3.414H4.828c-1.782 0-2.674-2.154-1.414-3.414l5-5A2 2 0 009 10.172V5L8 4z"
                    ></path>
                  </svg>
                </div>
              </div>
              <div class="ml-4">
                <dt class="text-lg font-medium leading-6 text-gray-900">
                  <a class="text-green-300 underline" href="#">Material</a>
                </dt>
                <dd class="mt-2 text-base text-gray-500">
                  Det finns många olika typer material, vi printar i PLA, PETG
                  och ASA. Om du är intresserade av andra material är det bara
                  att höra av sig.
                </dd>
              </div>
            </div>

            <div data-aos="fade-right" data-aos-duration="1200" class="flex">
              <div class="flex-shrink-0">
                <div
                  class="flex justify-center items-center w-12 h-12 text-white bg-green-300 rounded-md"
                >
                  <!-- Heroicon name: color-swatch -->
                  <svg
                    class="w-6 h-6"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M7 21a4 4 0 01-4-4V5a2 2 0 012-2h4a2 2 0 012 2v12a4 4 0 01-4 4zm0 0h12a2 2 0 002-2v-4a2 2 0 00-2-2h-2.343M11 7.343l1.657-1.657a2 2 0 012.828 0l2.829 2.829a2 2 0 010 2.828l-8.486 8.485M7 17h.01"
                    ></path>
                  </svg>
                </div>
              </div>
              <div class="ml-4">
                <dt class="text-lg font-medium leading-6 text-gray-900">
                  <a class="text-green-300 underline" href="#">Färgval</a>
                </dt>
                <dd class="mt-2 text-base text-gray-500">
                  WinterZone AB har ett stort lager med olika färger, skulle din
                  favoritfärg saknas så beställer vi hem färgen som kommer inom
                  några dagar.
                </dd>
              </div>
            </div>

            <div data-aos="fade-left" data-aos-duration="1200" class="flex">
              <div class="flex-shrink-0">
                <div
                  class="flex justify-center items-center w-12 h-12 text-white bg-green-300 rounded-md"
                >
                  <!-- Heroicon name: photograph -->
                  <svg
                    class="w-6 h-6"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z"
                    ></path>
                  </svg>
                </div>
              </div>
              <div class="ml-4">
                <dt class="text-lg font-medium leading-6 text-gray-900">
                  Upplösning
                </dt>
                <dd class="mt-2 text-base text-gray-500">
                  Våra skrivare klarar en detaljupplösning i XY-led på 0,3 mm, i
                  Z-led ner till 0,05 mm. Högre upplösning tar förstås längre
                  tid i skrivaren.
                </dd>
              </div>
            </div>
            <div data-aos="fade-right" data-aos-duration="1200" class="flex">
              <div class="flex-shrink-0">
                <div
                  class="flex justify-center items-center w-12 h-12 text-white bg-green-300 rounded-md"
                >
                  <!-- Heroicon name: archive -->
                  <svg
                    class="w-6 h-6"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M5 8h14M5 8a2 2 0 110-4h14a2 2 0 110 4M5 8v10a2 2 0 002 2h10a2 2 0 002-2V8m-9 4h4"
                    ></path>
                  </svg>
                </div>
              </div>
              <div class="ml-4">
                <dt class="text-lg font-medium leading-6 text-gray-900">
                  Leverans
                </dt>
                <dd class="mt-2 text-base text-gray-500">
                  Utskrifter skickas så fort de är klara, självklart över hela
                  världen. Vi erbjuder även avhämtning i Åby vid
                  överenskommelse.
                </dd>
              </div>
            </div>

            <div data-aos="fade-left" data-aos-duration="1200" class="flex">
              <div class="flex-shrink-0">
                <div
                  class="flex justify-center items-center w-12 h-12 text-white bg-green-300 rounded-md"
                >
                  <!-- Heroicon name: credit-card -->
                  <svg
                    class="w-6 h-6"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M3 10h18M7 15h1m4 0h1m-7 4h12a3 3 0 003-3V8a3 3 0 00-3-3H6a3 3 0 00-3 3v8a3 3 0 003 3z"
                    ></path>
                  </svg>
                </div>
              </div>
              <div class="ml-4">
                <dt class="text-lg font-medium leading-6 text-gray-900">
                  Betalning
                </dt>
                <dd class="mt-2 text-base text-gray-500">
                  Vi accepterar betalning med de vanligaste kreditkorten, Swish
                  och PayPal. Företag faktureras 30 dagar netto.
                </dd>
              </div>
            </div>
          </dl>
        </div>
      </div>
    </div>

    <div class="py-12 bg-white">
      <div class="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
        <div class="text-center">
          <h2
            class="mt-2 text-3xl font-extrabold tracking-tight leading-8 text-gray-900 sm:text-4xl"
          >
            Additiv tillverkning
          </h2>
        </div>
        <div class="mt-10">
          <dl
            class="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-10"
          >
            <div data-aos="fade-right" data-aos-duration="1200" class="flex">
              <div class="ml-4">
                <dt class="text-lg font-medium leading-6 text-gray-900">
                  FDM/FFF
                </dt>
                <dd class="mt-2 text-base text-gray-500">
                  (Fused Deposition Modelling/Fused Filament Fabrication),
                  innebär att en plasttråd smälts och spritsas ut i ett mönster.
                </dd>
              </div>
            </div>

            <div data-aos="fade-left" data-aos-duration="1200" class="flex">
              <div class="ml-4">
                <dt class="text-lg font-medium leading-6 text-gray-900">
                  DLP
                </dt>
                <dd class="mt-2 text-base text-gray-500">
                  (Digital Light Processing) även kallat resinutskrift, harts
                  som stelnar när den belyses med UV-ljus.
                </dd>
              </div>
            </div>
          </dl>
          <div data-aos="fade-up" ata-aos-duration="1200">
            <p class="mt-12 text-xl text-center">
              Vill du veta mer? Läs en mer utförlig text om FDM/FFF och DLP
              <a class="text-green-300" href="#">här</a>.
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="py-12 mt-48 bg-green-300">
      <div class="px-4 mx-auto sm:px-6">
        <div class="text-center">
          <p
            data-aos="zoom-in-down"
            data-aos-duration="1000"
            class="mt-2 text-3xl font-extrabold tracking-tight leading-8 text-white sm:text-4xl"
          >
            Varför välja oss?
          </p>

          <div class="flex flex-col md:flex-row md:justify-around">
            <div
              data-aos="zoom-in-right"
              data-aos-duration="1000"
              class="mx-auto mt-24 max-w-2xl text-xl text-white lg:w-1/3"
            >
              <p class="text-2xl">
                "Snabb leverans, fantastiska utskrifter! Mycket, mycket nöjd."
              </p>
              <p class="mt-4">
                <span class="font-bold">Andreas</span>
                <span class="mx-3 text-3xl font-bold text-green-600">/</span>
                trustpilot.com
              </p>
            </div>
            <div
              data-aos="zoom-in-left"
              data-aos-duration="1000"
              class="mx-auto mt-24 max-w-2xl text-xl text-white lg:w-1/3"
            >
              <p class="text-2xl">
                "Snabbt och bra resultat. Bra priser också!"
              </p>
              <p class="mt-4">
                <span class="font-bold">Jan</span>
                <span class="mx-3 text-3xl font-bold text-green-600">/</span>
                trustpilot.com
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="mt-12 ml-12 text-gray-600">
      Icons made by
      <a href="https://www.freepik.com" title="Freepik">Freepik</a> from
      <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a>.
    </div>
  </div>
</template>
